import { Image } from "./image";
import React from "react";

export const Gallery = (props) => {
  return (
      <div id="gallery" className="text-center">
        <div className="container">
          <div className="section-title">
            <h2>Galerie</h2>
            <p>
              Hier ein paar Eindrücke aus dem KickerEck.
            </p>
          </div>
          <div className="row">
            <div className="gallery-items">
              {props.data
                  ? props.data.map((d, i) => (
                      <div
                          key={`${d.title}-${i}`}
                          className="col-sm-6 col-md-4 col-lg-4"
                      >
                          <Image
                            title={d.title}
                            largeImage={d.largeImage}
                            smallImage={d.smallImage}
                        />
                      </div>
                  ))
                  : "Loading..."}
            </div>
          </div>
        </div>
      </div>
  );
};
