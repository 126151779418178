import React from "react";

export const About = () => {
  return (
    <div id="about" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>Über uns</h2>
          <p>
            Willkommen im KickerEck!<br/><br/>
            Egal, ob du ein leidenschaftlicher Tischfußballspieler bist oder gerade erst anfängst – bei uns findest
            du den perfekten Ort, um deine Fähigkeiten zu verbessern, neue Freunde zu treffen und jede Menge Spaß zu
            haben.
            <br/><br/>
          </p>
        </div>
          <div className="row">

            <div className="col-xs-12 col-md-6">
              {" "}
              <img src="img/about.jpg" className="img-responsive" alt=""/>{" "}
              <br/><br/>
            </div>
            <div className="col-xs-12 col-md-6">
              <div className="about-text">
                <p>
                  <b>Was wir bieten</b>
                  <br/><br/>
                  <b>Vielfältige Spielmöglichkeiten:</b> In unserem gemütlichen Vereinsheim stehen dir mehrere Tische
                  zur
                  Verfügung, an denen du jederzeit spielen kannst. Hier kannst du trainieren, entspannen und spannende
                  Matches bestreiten.
                  <br/><br/>
                  <b>Trainingsangebote:</b> Wir bieten verschiedene Trainingsmöglichkeiten an, um deine Technik zu
                  verbessern und neue Tricks zu erlernen. Unser erfahrenes Team steht dir dabei mit Rat und Tat zur
                  Seite
                  und hilft dir, dein Spiel auf das nächste Level zu bringen.
                  <br/><br/>
                  <b>Regelmäßige Turniere:</b> Im KickerEck veranstalten wir regelmäßig Turniere, die für alle
                  Spielniveaus geeignet sind. Von lockeren Spaßturnieren bis hin zu hochkarätigen Wettkämpfen – bei uns
                  ist für jeden etwas dabei. Teste deine Fähigkeiten und messe dich mit anderen Spielern.
                  <br/><br/>
                  <b>Familiäre Atmosphäre:</b> Das KickerEck ist mehr als nur ein Ort zum Tischfußballspielen. Es ist
                  ein
                  Treffpunkt, an dem du dich mit Freunden treffen, neue Leute kennenlernen und eine familiäre Atmosphäre
                  genießen kannst. Bei uns ist jeder willkommen.
                  <br/><br/>
                  Komm vorbei und überzeuge dich selbst vom KickerEck! Wir freuen uns darauf, dich kennenzulernen und
                  mit dir das Tischfußball-Erlebnis zu genießen.
                </p>
              </div>
            </div>
          </div>
      </div>
    </div>
);
};
