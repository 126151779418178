import React, { useState, useEffect } from "react";
import SnackbarProvider from "react-simple-snackbar";
import Navigation from "../components/navigation";
import { Header } from "../components/header";
import { KickCalendar} from "../components/calendar";
import { About } from "../components/about";
import { Modus } from "../components/modus";
import { Gallery } from "../components/gallery";
import { Contact } from "../components/contact";


import JsonData from "../data/data.json";
import SmoothScroll from "smooth-scroll";
import "../App.css";


export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});



  const Home = () => {
    const [landingPageData, setLandingPageData] = useState({});
    useEffect(() => {
      setLandingPageData(JsonData);
    }, []);
    return (
        <SnackbarProvider>
          <div>
            <Navigation />
            <Header data={landingPageData.Header} />
            <KickCalendar data={landingPageData.KickCalendar} />
            <Modus data={landingPageData.Modus} />
            <About data={landingPageData.About} />
            <Gallery data={landingPageData.Gallery} />
            <Contact data={landingPageData.Contact} />
          </div>
        </SnackbarProvider>
    );
  }
export default Home;