import React from 'react';
import {
    createBrowserRouter,
    RouterProvider,
} from "react-router-dom"
import { createRoot } from 'react-dom/client';
import './index.css';
import Layout from './pages/Layout';
import Home from "./pages/Home";
import Impressum from "./pages/Impressum";
import Challenger from "./pages/Challenger";
import Address from "./pages/Address";

import * as serviceWorker from './serviceWorker';


export default function App() {
    const router = createBrowserRouter([
        {
            // parent route component
            element: <Layout />,
            // child route components
            children: [
                {
                    path: "/",
                    element: <Home />,
                },
                // other pages....
                {
                    path: "/impressum",
                    element: <Impressum />,
                },
                {
                    path: "/challenger",
                    element: <Challenger />,
                },
                {
                    path: "/address",
                    element: <Address />,
                }
            ],
        },
    ])
    return (
        <RouterProvider router={router} />
    );
}

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
    <App />
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();





