import { useEffect, useState } from 'react'
import axios from 'axios'; // Import Axios

export default function FetchODData() {
    const [csvData, setCsvData] = useState([]);

    useEffect(() => {
        fetchODData();    // Fetch the CSV data when the component mounts
    }, []); // The empty array ensures that this effect runs only once, like componentDidMount

    const fetchODData = () => {
        const csvUrl = 'https://docs.google.com/spreadsheets/d/e/2PACX-1vQS3Y6YbrJ06zafqO_Mwq1jINMtx47OwhOT245kJpcdU6rKe3M7A0jJJk-pbhYpacWI3ZdUc0-nGhgF/pub?gid=1314596928&single=true&output=csv'; // Replace with your Google Sheets CSV file URL

        axios.get(csvUrl)    // Use Axios to fetch the CSV data
            .then((response) => {
                const parsedCsvData = parseCSV(response.data);        // Parse the CSV data into an array of objects
                setCsvData(parsedCsvData);        // Set the fetched data in the component's state
            })
            .catch((error) => {
                console.error('Error fetching CSV data:', error);
            });
    }

    function parseCSV(csvText) {
        const rows = csvText.split(/\r?\n/);        // Use a regular expression to split the CSV text into rows while handling '\r'
        const data = [];        // Initialize an array to store the parsed data
        for (let i = 0; i < rows.length; i++) {
            const rowData = rows[i].split(',');          // Use the regular expression to split the row while handling '\r'
            data.push(rowData);
        }
        return data;
    }
    return {csvData};
}