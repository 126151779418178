import React from 'react';
export const Modus = (props) => {
  return (
    <div id="modus" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>Was wird gespielt?</h2>
        </div>
        <p>
          Bei uns werden in der Regel "Mini Challenger" gespielt - kleine Turniere, die zwar nach offiziellen Regeln gespielt werden, aber dennoch eher zwanglos sind. Bei uns steht der Spaß im Vordergrund. Die
          verschiedenen Varianten werden hier gerklärt.
        </p><br/><br/><br/>
        <div className="row">
          {props.data
              ? props.data.map((d, i) => (
                  <div key={`${d.name}-${i}`} className="col-md-4">
                    {" "}
                    <img src={`./img/icons/${d.icon}`} alt={d.name} width="42" height="42"></img>
                    <div className="modus-desc">
                      <h3>{d.name}</h3>
                      <p>{d.text}</p>
                    </div>
                  </div>
              ))
              : "loading"}
        </div>
      </div>
    </div>
  );
};
