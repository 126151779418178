import NavigationImp from "../components/navigation-imp";
import React from 'react'
import {default as FetchODData}  from "../components/FetchODData";
import {default as FetchOEData}  from "../components/FetchOEData";
import {Link} from "react-router-dom";


const Challenger = () => {

    return (
        <>
            <NavigationImp/>
            <header id="header"/>
            <div id="challenger">
                <div id="challenger-title" className="text-center">
                    <div className="section-title">
                        <h1>Anmeldung Mini-Challenger</h1>
                    </div>
                </div>
                <div id="cframe">
                    <iframe
                        src="https://docs.google.com/forms/d/e/1FAIpQLSfBTKIHcaE_tXYI11icv4HUX-pwEzM6_WdPikKzA0nBq7IZww/viewform?embedded=true"
                        height="650px" width="100%"
                        title="ApplianceForm">Loading…
                    </iframe>
                </div>
                <div className="reload">
                    <p>
                        Die Aktualisierung der Listen dauert ein paar Sekunden. Sie müssen ggf. die Listen neu laden.
                    </p>
                    <Link to="/challenger"
                          className="btn btn-custom btn-lg page-scroll"
                          reloadDocument
                    >
                        Anmeldelisten neu laden
                    </Link>
                </div>
                <div className="section-title">
                    <h1>Teilnehmer nächstes OD</h1>
                </div>
                <table>
                    <thead>
                    {FetchODData().csvData.map((item, idx) =>
                        idx === 0 ? (
                                <tr key="ODheader">
                                    {item.map((value) => (
                                        <th key={value}>{value}</th>
                                    ))}
                                </tr>
                            ) :
                            (<></>
                            ))}
                    </thead>
                    <tbody>
                    {FetchODData().csvData.map((item, idx) =>
                        idx === 0 ? (
                                <></>
                            ) :
                            (
                                <tr key={idx}>
                                    {item.map((value, idx2) => (
                                        <td key={""+idx+":"+idx2+":"+value}>{value}</td>
                                    ))}
                                </tr>
                            )
                    )}
                    </tbody>
                </table>

                <div className="section-title">
                    <h1>Teilnehmer nächstes OE</h1>
                </div>
                <table>
                    <thead>
                    {FetchOEData().csvData.map((item, idx) =>
                        idx === 0 ? (
                                <tr key="OEheader">
                                    {item.map((value) => (
                                        <th key={value}>{value}</th>
                                    ))}
                                </tr>
                            ) :
                            (<></>
                            ))}
                    </thead>
                    <tbody>
                    {FetchOEData().csvData.map((item, idx) =>
                        idx === 0 ? (
                                <></>
                            ) :
                            (
                                <tr key={idx}>
                                    {item.map((value, idx2) => (
                                        <td key={""+idx+":"+idx2+":"+value}>{value}</td>
                                    ))}
                                </tr>
                            )
                    )}
                    </tbody>
                </table>
            </div>
        </>
    );
}

export default Challenger;