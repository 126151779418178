import React from "react";
import SmoothScroll from "smooth-scroll";
import NavigationImp from "../components/navigation-imp";

export const scroll = new SmoothScroll('a[href*="#"]', {
    speed: 1000,
    speedAsDuration: true,
});



const Address = () => {
    return (
        <>
            <NavigationImp/>
            <header id="header">
                <div id="address">
                    <h1>Anfahrt</h1>
                    <iframe title = "WayToKickerEck"
                            src="https://www.google.com/maps/embed/v1/place?q=place_id:ChIJG72IY90XuUcRYiUbUQsnADQ&key=AIzaSyAiKQ6QUGkTswalabjnF_us312X5T40c2Q"></iframe>
                    <h1>Parken</h1>
                    <img src="img/SkizzeLogo.jpg" className="img-responsive" alt="Skizze" />{" "}
                    <br/>
                    <p>
                        Das KickerEck befindet sich in der Tremoniaschule (blaue Fläche).
                        Parken könnt Ihr in den rot eingezeichneten Flächen.
                    </p>
                </div>
            </header>
        </>
    );
}

export default Address;