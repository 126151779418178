import React from "react";
import {Link} from "react-router-dom";

export const KickCalendar = () => {
    return (
    <div id="KickCalendar" className="text-center">
            <div className="section-title">
                <h2>Kalender</h2>
            </div>
        <div className="calendar">
            <iframe
                title="Styled Calendar"
                data-cy="calendar-embed-iframe"
                src="https://embed.styledcalendar.com/#70tCZ8znkzXNoP8ghbhS"
                width="100%"
                height="650px"
            ></iframe>
            <br/><br/><br/>
            <Link to="/challenger"
                className="btn btn-custom btn-lg page-scroll"
                  reloadDocument
            >
                Anmeldung MiniChallenger
            </Link>{" "}
        </div>
    </div>
    );
}


